import { useEffect } from 'react'

import { Install } from '@pages/Install'
import { App } from '@pages/App'
import './global.css'

export const Main = () => {
  const isPWAInstalled = window.matchMedia('(display-mode: standalone)').matches

  useEffect(() => {
    const params = window.location.search

    localStorage.setItem('params', params)
  }, [])

  if (!isPWAInstalled) return <Install />

  return (
    <>
      {!isPWAInstalled && <Install />}
      <App isDisplay={isPWAInstalled} />
    </>
  )
}
